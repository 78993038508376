import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut
} from 'firebase/auth'
import { auth } from '../../../config/firebase';

// Server should return AuthModel
export function login(email: string, password: string) {
  signInWithEmailAndPassword(auth, email, password);
  // TODO - add error handling
}

// Server should return AuthModel
export function register(
  email: string,
  password: string,
) {
  createUserWithEmailAndPassword(auth, email, password);
  // TODO - add error handling
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return sendPasswordResetEmail(auth, email);
  // TODO - DELETE
}

export function currentUser() {
  return auth.currentUser;

}

export function logout() {
  return signOut(auth);
  // TODO - add error handling
}