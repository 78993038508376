import { lazy, Suspense, useEffect } from "react";
import {useLocalStorage} from "usehooks-ts";

const DarkTheme = lazy(() => import("./DarkTheme"));
const LightTheme = lazy(() => import("./LightTheme"));

// @ts-ignore
export const ThemeProvider = ({ children }) => {
  const [darkMode] = useLocalStorage<boolean>("isDarkMode", false);
  useEffect(() => {
    console.log("darkMode", darkMode);
  }, [darkMode]);
    
  return (
    <>
      <Suspense fallback={<span />}>
        {darkMode ? <DarkTheme /> : <LightTheme />}
      </Suspense>
      {children}
    </>
  );
};