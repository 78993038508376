import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
const firebaseConfig = {
    apiKey: "AIzaSyCEqD4BLJF2fPFxURa3PAJ7JlS9Ku-Q-ik",
    authDomain: "ts-stripe.firebaseapp.com",
    projectId: "ts-stripe",
    storageBucket: "ts-stripe.appspot.com",
    messagingSenderId: "603973276902",
    appId: "1:603973276902:web:cc758467b17c285637d727",
    measurementId: "G-DGG3CQB4VE"
};

// Initialize Firebase
const Firebase = initializeApp(firebaseConfig);


// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(Firebase);

export default Firebase;