import ReactDOM from 'react-dom'
// Axios
import {Chart, registerables} from 'chart.js'
import {QueryClient, QueryClientProvider} from 'react-query'
import {ReactQueryDevtools} from 'react-query/devtools'

// Apps
import {MetronicI18nProvider} from './_metronic/i18n/Metronici18n'
/**
 * TIP: Replace this style import with dark styles to enable dark mode
 *
 * import './_metronic/assets/sass/style.dark.scss'
 *
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
// import 'antd/dist/antd.dark.css'
// import './_metronic/assets/sass/style.dark.scss'


import './_metronic/assets/sass/style.react.scss'
import {AppRoutes} from './app/routing/AppRoutes'
import { ThemeProvider } from './app/theme/ThemeProvider'

Chart.register(...registerables)

const queryClient = new QueryClient()

ReactDOM.render(
  <ThemeProvider>
    <QueryClientProvider client={queryClient}>
      <MetronicI18nProvider>
        <AppRoutes />
      </MetronicI18nProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </ThemeProvider>,
  document.getElementById('root')
)
