/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {Input} from 'antd'
import { Select } from 'antd';

const { Option, OptGroup } = Select;
const {TextArea} = Input


function handleChange(value: string) {
  console.log(`selected ${value}`);
}

const DashboardPage: FC = () => {
  const [value, setValue] = useState('')

  return (
    <>
      {/* begin::Row */}
      <div className='row'>
        {/* begin::Col */}
        <div className='col'>
          <div className='card card-bordered'>
            <div className="card-header justify-content-end ribbon ribbon-start">
              <div className="ribbon-label bg-primary">Input</div>
              <div className="card-title">
                <Select defaultValue="rewrite" style={{ width: 200 }} onChange={handleChange}>
                  {/* Rewriter fonctionnalités :
                      - résumé
                      - réécriture
                      - augmenter le volumes de mots
                      - Trouver une problématique
                      - Trouver les mots clés  */}
                  <OptGroup label="Rewriter">
                    <Option value="summary">Summary</Option>
                    <Option value="expand">Expand</Option>
                    <Option value="rewrite">Rewrite</Option>

                  </OptGroup>
                  <OptGroup label="Extractor">
                    <Option value="key_question">Key question</Option>
                    <Option value="key_words">Key words</Option>
                    
                    
                  </OptGroup>
                </Select>
              </div>
            </div>
          
            <TextArea
              style={{
                width: '100%',
                borderColor: '#dee2e6',
                borderBottomRightRadius: '10px',
                borderBottomLeftRadius: '10px',
                padding: '10px',
                fontSize: '14px',
              }}
              value={value}
              onChange={(e) => setValue(e.target.value)}
              placeholder='Write or past your text here'
              autoSize={{minRows: 15}}
            />
          </div>
        </div>
        {/* end::Col */}
        {/* begin::Col */}
        <div className='col'>
          <div className='card card-bordered'>
            <div className="card-header justify-content-end ribbon ribbon-start">
              <div className="ribbon-label bg-primary">Output</div>
              <div className="card-title">
                <span className="badge badge-light-primary">
                  need help ?
                </span>
              </div>
            </div>
            <TextArea
              style={{
                width: '100%',
                borderColor: '#dee2e6',
                borderBottomRightRadius: '10px',
                borderBottomLeftRadius: '10px',
                padding: '10px',
                fontSize: '14px',
              }}
              value={value}
              onChange={(e) => setValue(e.target.value)}
              autoSize={{minRows: 15}}
            />
          </div>
        </div>
        {/* end::Col */}
      </div>
    </>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
