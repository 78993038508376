import {useEffect} from 'react'
import {Navigate, Routes} from 'react-router-dom'
import { logout } from './core/_requests'

export function Logout() {
  useEffect(() => {
    logout()
    document.location.reload()
  }, [])

  return (
    <Routes>
      <Navigate to='/auth/login' />
    </Routes>
  )
}
